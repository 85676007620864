.titleBlue {
  color: #364564;
  font-family: Work Sans;
  font-size: 42px;
  font-style: normal;
  font-weight: 700;
  line-height: 109.969%; /* 46.187px */
}
.titleGreen {
  color: #8fb869;
  font-family: Work Sans;
  font-size: 42px;
  font-style: normal;
  font-weight: 700;
  line-height: 109.969%;
}

.points {
  color: #364564;
  font-family: Work Sans;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 109.969%; /* 19.794px */
}

li {
  margin-top: 16px;
}
