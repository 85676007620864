.title {
  color: #fff;
  text-align: center;
  font-family: Raleway;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.img {
  flex-shrink: 0;
  background-image: url('/public/assets/images/onboarding/food-bowl.png');
  background-repeat: no-repeat;
  background-size: cover;
  min-height: 100%;
  min-width: 100%;
  max-width: max-content;
  display: flex;
  flex-direction: column;
  justify-content: end;
  align-items: stretch;
  transform: translateY(-200px);
  position: absolute;
}

.form {
  background: rgba(0, 0, 0, 0.8);
  margin: auto;
  position: relative;
}


