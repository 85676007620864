.close {
  cursor: pointer;
}

.img {
  width: 116px;
  height: 92px;
  cursor: pointer;
}

@media only screen and (min-width: 900px) {
  .img {
    width: 65px;
    height: 52px;
  }
}

.container {
  transform: translateY(-50px);
}

.videoContainer::after {
  content: '';

  position: absolute;
  min-width: 100vw;
  min-height: 1280px;
  background-color: rgba(10, 10, 10, 0.7);
  z-index: 1;
  left: 0;
  top: 0;
}

.videoContainer {
  position: absolute;
}
