.title {
  color: #364564;
  font-family: Work Sans;
  font-size: 48px;
  font-style: normal;
  font-weight: 700;
  text-align: center;
  line-height: 109.969%; /* 52.785px */
}

.subtitle {
  color: #364564;
  font-family: Work Sans;
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: 100%; /* 24px */
  letter-spacing: -0.48px;
  text-align: center;
}

.subtitleBold {
  color: #364564;
  font-family: Work Sans;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 100%;
  letter-spacing: -0.48px;
  -webkit-text-decoration-line: underline;
  text-decoration-line: underline;
  text-align: center;
}

.subtitleWhite {
  color: #fff;
  font-family: Work Sans;
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: 100%; /* 24px */
  letter-spacing: -0.48px;
  text-align: center;
}

.subtitleBoldWhite {
  color: #fff;
  font-family: Work Sans;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 100%;
  letter-spacing: -0.48px;
}

.infoText1 {
  color: #364564;
  font-family: Work Sans;
  font-size: 32px;
  font-style: normal;
  font-weight: 700;
  line-height: 100%; /* 32px */
  letter-spacing: -0.64px;
}

.infoText2 {
  border-radius: 20px;
  background: #8bc540;
  display: flex;
  padding: 8px;
  align-items: flex-start;
  gap: 10px;
  color: #fff;
  font-family: Work Sans;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 100%; /* 16px */
  letter-spacing: -0.32px;
}

.infoText3 {
  color: #7e7e7e;
  font-family: Work Sans;
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: 100%; /* 24px */
  letter-spacing: -0.48px;
  -webkit-text-decoration-line: line-through;
  text-decoration-line: line-through;
}

.infoText4 {
  color: #8e8e8e;
  font-family: Work Sans;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 100%; /* 16px */
  letter-spacing: -0.32px;
}
