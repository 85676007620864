.pinButton {
  background: linear-gradient(
    180deg,
    rgba(255, 255, 255, 0) -10.61%,
    #fff 23.48%
  );
  height: 120px;
  flex-shrink: 0;
  position: fixed;
  bottom: 0px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.buttonSytle {
  display: inline-flex;
  padding: 12px 30px;
  align-items: flex-start;
  gap: 10px;
  color: #fff;
  text-align: center;
  font-feature-settings: 'clig' off, 'liga' off;
  font-family: Work Sans;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
