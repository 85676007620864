.title {
  color: #191919;
  font-family: Work Sans;
  font-size: 42px;
  font-style: normal;
  font-weight: 700;
  line-height: 109.969%; /* 46.187px */
  text-align: center;
}

.points {
  color: #8fb869;
  text-align: center;
  font-family: Work Sans;
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: 109.969%; /* 26.392px */
  letter-spacing: -0.48px;
  text-align: center;
}
