.title {
  color: #364564;
  text-align: center;
  font-family: Work Sans;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 109.969%; /* 17.595px */
}

.icon {
  cursor: pointer;
}

.link {
  color: #354465;
  text-align: center;
  font-family: Rubik;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.url:link,
.url:hover,
.url:visited,
.url:active {
  -webkit-text-decoration-line: underline;
  text-decoration-line: underline;
  color: #354465;
}
