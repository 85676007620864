.title {
  color: #fff;
  text-align: center;
  font-family: Work Sans;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 109.969%; /* 17.595px */
}

.icon {
  cursor: pointer;
}
