.title {
  color: #fff;
  font-family: Work Sans;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 109.969%; /* 17.595px */
}
.subtitle {
  color: #fff;
  font-family: Work Sans;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 109.969%;
  white-space: pre-wrap;
}
